import { graphql } from "gatsby"

import MDXRenderer from "gatsby-plugin-mdx/mdx-renderer"

// import { useIsAuthenticated } from "@azure/msal-react"

import SEO, { getSeoProps } from "../components/Common/SEO"

import { RelatedSectionProps, RelatedProductsSection } from "../components/Sections/RelatedSection"

import HeroProductGroups from "../components/Hero/HeroProductGroups"
import getBannerProps from "../components/Hero/getHeroBannerProps"

import getMainPath from "../components/Common/MainPaths"
// import getAuthor from "../components/Common/getAuthor"

/**
 * @description Creates one unique page pr product.
 */
const ProductTemplate = ({ location, data }) => {
  const mainPath = getMainPath("productgroup")

  const { contentfulProductTeaser: content } = data

  let {
    allContentfulProductTeaser: { nodes: allContent },
  } = data

  if (allContent !== null && allContent !== undefined) {
    allContent = allContent.filter(
      (x) => x.id !== content.id && x.productGroup !== undefined && x.productGroup !== null
    )

    allContent.sort((a, b) => a.order - b.order)
    if (allContent.length > 3) {
      const size = 3
      allContent = allContent.slice(0, size)
    }
  }

  // Parse the content and create the props needed by SEO component.
  const helmetProps = getSeoProps(content, location, "product")

  // Parse the content, and get the needed banner props
  const heroBannerProps = getBannerProps(content)

  const relatedProps: RelatedSectionProps = {
    title: "Andre produkter",
    path: "nb" + mainPath + "/",
    data: allContent,
  }

  return (
    <>
      <SEO {...helmetProps} />
      <main>
        <article>
          <HeroProductGroups {...heroBannerProps} />

          <div className="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 mt-8 lg:mt-0 ">
            {content.body?.childMdx?.body ? (
              // Ikke alle produkter har innhold
              <MDXRenderer>{content.body.childMdx.body}</MDXRenderer>
            ) : (
              <div></div>
            )}
          </div>
        </article>
      </main>
      <RelatedProductsSection {...relatedProps} />
    </>
  )
}

export default ProductTemplate

export const productQuery = graphql`
  query productsQuery($slug: String!) {
    contentfulProductTeaser(slug: { eq: $slug }, node_locale: { eq: "nb-NO" }) {
      id
      heroImage {
        id
        public_id
        context {
          custom {
            Credits
            alt
          }
        }
        url
        secure_url
        width
        height
        format
      }
      contentful_id
      slug
      node_locale
      backgroundColor
      title
      updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
      createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
      ingress {
        ingress
        childMdx {
          timeToRead
        }
      }
      body {
        childMdx {
          timeToRead
          body
        }
      }
      productGroup {
        slug
      }
    }
    allContentfulProductTeaser(filter: { node_locale: { eq: "nb-NO" } }) {
      nodes {
        id
        contentful_id
        title
        slug
        productGroup {
          slug
        }
        heroImage {
          id
          public_id
          context {
            custom {
              Credits
              alt
            }
          }
          url
          secure_url
          width
          height
          format
        }
        node_locale
        createdAt(formatString: "DD.MM.YYYY hh:mm:ss")
        updatedAt(formatString: "DD.MM.YYYY hh:mm:ss")
        ingress {
          ingress
          childMdx {
            timeToRead
          }
        }
        body {
          childMdx {
            timeToRead
            body
          }
        }
      }
    }
  }
`
